import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import AgentHeader from 'components/Agent/AgentHeader';

export default ({children}) => {
  return (
    <Container fluid style={{backgroundColor: '#edf2f6'}}>
      <AgentHeader />
      <Row style={{minHeight: '100vh'}}>
        <Container fluid>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
