import {useEffect, useState} from 'react';
import {useAppointmentState} from 'context/agent/appointment-context';
import {useAuth} from 'context/agent/auth-context';
import {fetchDatesOpen, fetchAvailableSlots} from 'api/customer';

const useAgentNewAppointment = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [loadingTime, setLoadingTime] = useState(false);
  const [errorTime, setErrorTime] = useState(false);
  const [datesOpen, setDatesOpen] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);

  const {inStore} = useAppointmentState();
  const {getStoreId, authState} = useAuth();
  const storeId = getStoreId();
  const bookingType = inStore ? 'inShop' : 'away';
  const selectedDate = datesOpen.find(({selected}) => selected)?.date;
  const selectedSlot = availableSlots.find(({selected}) => selected);

  useEffect(() => {
    async function getDatesOpen() {
      setLoadingTime(true);
      setErrorTime(false);
      try {
        const response = await fetchDatesOpen({
          storeId,
          bookingType
        });
        setDatesOpen(response.map((record) => ({...record, selected: false})));
      } catch (error) {
        setErrorTime(error?.code ?? true);
      } finally {
        setLoadingTime(false);
      }
    }

    if (storeId) {
      getDatesOpen();
    }
  }, [bookingType, storeId]);

  useEffect(() => {
    async function getAvailableSlots() {
      setLoadingTime(true);
      setErrorTime(false);
      try {
        const response = await fetchAvailableSlots({
          storeId,
          date: selectedDate,
          bookingType
        });
        setAvailableSlots(
          response.map((record) => ({
            ...record,
            period: `${record.time.slice(0, -3)} - ${record.endTime.slice(0, -3)}`,
            selected: false
          }))
        );
      } catch (error) {
        setErrorTime(error?.code ?? true);
      } finally {
        setLoadingTime(false);
      }
    }

    if (selectedDate && storeId) {
      getAvailableSlots();
    }
  }, [selectedDate, bookingType, storeId]);

  const onSelectDate = (selectedDate) => {
    setDatesOpen((dates) =>
      dates.map((record) => ({
        ...record,
        selected: record.date === selectedDate
      }))
    );
    setAvailableSlots([]);
  };

  const onSelectSlot = ({time, endTime}) =>
    setAvailableSlots((slots) =>
      slots.map((record) => ({
        ...record,
        selected: record.time === time && record.endTime === endTime
      }))
    );

  return [
    authState?.brand,
    storeId,
    loadingSubmit,
    errorSubmit,
    loadingTime,
    errorTime,
    datesOpen,
    selectedDate,
    availableSlots,
    selectedSlot,
    onSelectDate,
    onSelectSlot,
    setLoadingSubmit,
    setErrorSubmit
  ];
};

export default useAgentNewAppointment;
