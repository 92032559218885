import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {Col} from 'react-bootstrap';

const DatesOpen = ({datesOpen, onSelectDate}) => (
  <section className="fieldset mt-4">
    <div className="dates-open slot-dates d-flex justify-content-between pb-3">
      {datesOpen.map(({date, available, selected}) => {
        const classes = classnames('slot date', {
          disabled: !available,
          selected
        });

        return (
          <Col
            key={date}
            className="d-flex flex-column align-items-center mr-3 mr-sm-0 slot-col"
            onClick={() => (!available || selected ? undefined : onSelectDate(date))}
          >
            {moment(date).format('dd')}
            <span className={classes}>{moment(date).format('D')}</span>
          </Col>
        );
      })}
    </div>
  </section>
);

export default DatesOpen;
