import React, {Fragment, useState, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import Loader from 'components/common/Loader';
import ListTransformer from './ListTransformer';
import {getBookedAppointments} from 'api/agent/appointment';
import {useAppointmentState, useAppointmentDispatch} from 'context/agent/appointment-context';
import {useAuthFetch} from 'context/agent/fetch-context';
import {useAuth} from 'context/agent/auth-context';
import {bookedAppointmentSuccess} from 'context/agent/actions';
import {groupByTime} from 'components/Agent/Appointments/Group/transformer';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import {errors} from 'utils/error';

const currentDate = moment();
currentDate.startOf('day');

const AppointmentsList = () => {
  const {getStoreId, isCallCenter, authState} = useAuth();
  const dispatch = useAppointmentDispatch();
  const {authAxios} = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {date: dateParam} = useParams();
  const date = moment(dateParam);
  const {
    appointments,
    searchResults,
    queried,
    lang,
    bookedListFilter,
    inStore
  } = useAppointmentState();
  const [data, setData] = useState([]);

  const appointmentGroups = React.useMemo(() => {
    if (bookedListFilter !== 'ALL') {
      const filtered = appointments.filter((a) => a.orderStatus.status === bookedListFilter);
      return groupByTime(filtered);
    }

    return groupByTime(appointments);
  }, [appointments, bookedListFilter]);
  const storeId = getStoreId();
  const isCallCenterAgent = isCallCenter();
  const [shouldFetchBooked] = useState(() => !isCallCenterAgent);

  useEffect(() => {
    async function onGetBookApointments() {
      setError(false);
      setLoading(true);
      try {
        const bookedAppointments = await getBookedAppointments({
          authAxios,
          date: dateParam,
          storeId,
          lang,
          brand: authState?.brand,
          bookingType: inStore ? 'inShop' : 'away'
        });

        bookedAppointmentSuccess({dispatch, bookedAppointments});
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    }
    if (shouldFetchBooked) {
      onGetBookApointments();
    }
  }, [authAxios, authState.brand, dateParam, dispatch, lang, shouldFetchBooked, storeId, inStore]);

  useEffect(() => {
    if (queried) {
      setData(searchResults);
    } else {
      setData(appointmentGroups);
    }
  }, [appointmentGroups, queried, searchResults]);

  const dayFormat = date.isSame(currentDate)
    ? `Σήμερα, ${date.format('D MMM')}`
    : date.format('dddd, D MMM');

  const listTransformer = useMemo(
    () => <ListTransformer data={data} queried={queried} dayFormat={dayFormat} />,
    [data, queried, dayFormat]
  );

  return (
    <Fragment>
      <section className="position-relative section-premier appointments-list row flex-column">
        {loading ? (
          <div
            className="d-flex w-100 justify-content-center align-items-center"
            style={{position: 'absolute', top: -80, bottom: 0}}
          >
            <Loader />
          </div>
        ) : (
          <Fragment>
            {error ? <ErrorInfo text={errors[error.code]} hideHr /> : listTransformer}
          </Fragment>
        )}
      </section>
    </Fragment>
  );
};

export default AppointmentsList;
