import * as appointment from 'context/agent/constants/ActionTypes';

const searchAppointmentSuccess = ({dispatch, searchResults}) =>
  dispatch({type: appointment.SEARCH_APPOINTMENTS_SUCCESS, payload: searchResults});

const searchAppointmentFail = ({dispatch}) =>
  dispatch({type: appointment.SEARCH_APPOINTMENTS_FAIL});

const toggleQueried = ({dispatch, queried}) => {
  dispatch({type: appointment.TOGGLE_QUERIED, payload: queried});
};

const bookedAppointmentSuccess = ({dispatch, bookedAppointments}) => {
  dispatch({type: appointment.FETCH_BOOKED_APPOINTMENTS_SUCCESS, payload: bookedAppointments});
};

const toggleInStore = ({dispatch, payload}) => {
  dispatch({type: appointment.TOGGLE_IN_STORE, payload});
};

export {
  searchAppointmentSuccess,
  searchAppointmentFail,
  toggleQueried,
  bookedAppointmentSuccess,
  toggleInStore
};
