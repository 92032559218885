import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import 'moment/locale/el';

import {default as Pickup} from 'components/Customer';
import ClickInShop from 'components/Customer/ClickInShop';
import ThemeSelector from 'components/Theme/ThemeSelector';
import {AuthProvider} from 'context/agent/auth-context';
import {CustomerProvider} from 'context/customer/customer-context';
import {displayFavicon, isClickInShop} from 'utils/brand';
import reportWebVitals from './reportWebVitals';
import CustomerLayout from 'components/layouts/customer-layout';
import AgentLayout from 'components/layouts/agent-layout';
import Agent from 'components/Agent';
import {
  reducer as customerReducer,
  initialState as customerInitialState
} from 'context/customer/customerReducer';
import {
  reducer as clickInShopReducer,
  initialState as clickInShopInitialState
} from 'context/customer/clickInShop/clickinShopReducer';

// Start the mocking conditionally.
if (process.env.REACT_APP_MOCKAPI === 'true') {
  const {worker} = require('./mocks/browser');
  worker.start();
}

displayFavicon();

const reducer = isClickInShop ? clickInShopReducer : customerReducer;
const initialState = isClickInShop ? clickInShopInitialState : customerInitialState;

const Customer = () => {
  return (
    <CustomerProvider reducer={reducer} initialState={initialState}>
      <CustomerLayout>{isClickInShop ? <ClickInShop /> : <Pickup />}</CustomerLayout>
    </CustomerProvider>
  );
};

ReactDOM.render(
  <ThemeSelector>
    <BrowserRouter>
      <Switch>
        <Route exact path={['/', '/populate']}>
          <Customer />
        </Route>
        <Route path="/agent">
          <AuthProvider>
            <AgentLayout>
              <Agent />
            </AgentLayout>
          </AuthProvider>
        </Route>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </ThemeSelector>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
