import moment from 'moment';

const dateFormatted = (date) => moment(date || new Date()).format('YYYY-MM-DD');

const appointmentsRoute = (dateParam) => {
  return `/agent/appointments/${dateFormatted(dateParam)}`;
};

const dashboardRoute = (fromDate, toDate) => {
  return `/agent/dashboard/${dateFormatted(
    fromDate || moment().subtract(1, 'months')
  )}to${dateFormatted(toDate)}`;
};

export {appointmentsRoute, dashboardRoute};
