/**
 *
 * @param {Array} list
 * @param {Function} accesspr
 * @returns {Map}
 */
function groupByTime(list) {
  const grouped = list.reduce((acc, items) => {
    const group = `${items.slotDto.time.slice(0, -3)} - ${items.slotDto.endTime.slice(0, -3)}`;
    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push(items);

    return acc;
  }, {});

  return grouped;
}

export {groupByTime};
