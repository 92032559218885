import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {appointmentsRoute} from 'utils/routes';

const CreateAppointment = () => {
  const history = useHistory();
  const {date: dateParam} = useParams();
  const match = appointmentsRoute(dateParam);

  const onCreateAppointment = () => history.push(`${match}/new-appointment`);

  return (
    <button className="icon button--nostyle text-white" onClick={onCreateAppointment}>
      <i className="fa fa-plus" />
    </button>
  );
};

export default CreateAppointment;
