import React from 'react';
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom';

import {useAuth} from 'context/agent/auth-context';
import {AppointmentProvider} from 'context/agent/appointment-context';
import {FetchProvider} from 'context/agent/fetch-context';
import Login from 'components/Agent/Login';
import {getISODate} from 'utils/time';
import Appointments from 'components/Agent/Appointments';
import Calendar from 'components/Agent/Calendar';

export const AuthenticatedRoute = ({component: Component, ...rest}) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(ownProps) =>
        auth.isAuthenticated() ? (
          <Component {...ownProps} {...rest} />
        ) : (
          <Redirect to="/agent/login" />
        )
      }
    ></Route>
  );
};

const Agent = () => {
  const {url} = useRouteMatch();
  const {isAuthenticated} = useAuth();

  return (
    <Switch>
      <Route exact path={`${url}/login`} component={Login} />
      {!isAuthenticated() && <Redirect to={`${url}/login`} />}}
      <AppointmentProvider>
        <FetchProvider>
          <Switch>
            <AuthenticatedRoute path={`${url}/appointments/:date`} component={Appointments} />
            <AuthenticatedRoute path={`${url}/calendar`} component={Calendar} />
            <Redirect to={`${url}/appointments/${getISODate()}`} />
          </Switch>
        </FetchProvider>
      </AppointmentProvider>
    </Switch>
  );
};

export default Agent;
