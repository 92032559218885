import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OrderDialog from 'components/Agent/Features/OrderDialog';

const Basket = ({appointment, dialog, toggleDialog}) => (
  <OrderDialog
    showDialog={dialog}
    appointment={appointment}
    rightColClasses="px-0"
    close={toggleDialog}
  >
    {appointment.order.cartItems.map(({skuId, displayName, commerceItemQuantity}) => (
      <Fragment key={skuId}>
        <hr />
        <div className="px-4">
          {displayName && (
            <div>
              {displayName}
              {commerceItemQuantity > 1 && ` x ${commerceItemQuantity}`}
            </div>
          )}
          <div className="text-gray-secondary">#{skuId}</div>
        </div>
      </Fragment>
    ))}
  </OrderDialog>
);

Basket.proptTypes = {
  appointment: PropTypes.object.isRequired,
  dialog: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired
};

export default Basket;
