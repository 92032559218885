import React from 'react';
import moment from 'moment';

import SectionBrand from 'components/Agent/SectionBrand';
import InStoreSwitch from 'components/Agent/Appointments/Bar/InStoreSwitch';

const CalendarHeader = ({date, setDate}) => {
  const currentDateLabel = () => moment(date).format('MMMM yyyy');

  const goToPreviousMonth = () => {
    const previousMonth = moment(date).subtract(1, 'months');
    setDate(previousMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = moment(date).add(1, 'months');
    setDate(nextMonth);
  };

  return (
    <SectionBrand sectionClass="bar fixed-top row">
      <div className="d-flex justify-content-center align-items-center col col-8 offset-2">
        <i
          className="icon text-white fa fa-chevron-left cursor-pointer mr-5 p-2"
          onClick={goToPreviousMonth}
        />
        <span className="fs-18">{currentDateLabel()}</span>
        <i
          className="icon text-white fa fa-chevron-right cursor-pointer ml-5 p-2"
          onClick={goToNextMonth}
        />
      </div>
      <div className="col col-2">
        <InStoreSwitch />
      </div>
    </SectionBrand>
  );
};

export default CalendarHeader;
