import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {Dialog} from '@reach/dialog';
import CustomerDetails from 'components/Agent/CustomerDetails';
import '@reach/dialog/styles.css';

const OrderDialog = ({showDialog, appointment, close, rightColClasses, children}) => (
  <Dialog
    isOpen={showDialog}
    onDismiss={close}
    className="main-card position-relative"
    aria-label="Comment"
  >
    <Row>
      <Col style={{flexGrow: 30}} className="leftColumn">
        <CustomerDetails {...appointment} />
      </Col>
      <Col style={{flexGrow: 70}} className={rightColClasses}>
        {children}
      </Col>
    </Row>
    <button className="icon button--noStyle dialog-close" onClick={close}>
      <i className="fa fa-times-circle" aria-hidden="true"></i>
    </button>
  </Dialog>
);

OrderDialog.defaultProps = {
  rightColClasses: ''
};

OrderDialog.proptTypes = {
  showDialog: PropTypes.bool.isRequired,
  appointment: PropTypes.object.isRequired,
  rightColClasses: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default OrderDialog;
