import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import useStatus from 'hooks/useStatus';
import {setAppointmentStatus} from 'api/agent/appointment';
import {useAuthFetch} from 'context/agent/fetch-context';

const orderStatuses = [
  {
    status: 'IN_WAREHOUSE',
    label: 'Στην Αποθήκη',
    color: '#707070'
  },
  {
    status: 'ON_PICKUP',
    label: 'Στο Pickup Corner',
    color: '#FFEB3B'
  },
  {
    status: 'DELIVERED',
    label: 'Παραδόθηκε',
    color: '#009688'
  },
  {
    status: 'NO_SHOW',
    label: 'Δεν ήρθε',
    color: '#AF4448'
  }
];

const DropdownActions = ({status, appointmentId}) => {
  const {authAxios} = useAuthFetch();
  const {error, statusSelected, statuses, setSelected, setError} = useStatus(status, orderStatuses);

  async function onSetStatus(status) {
    try {
      await setAppointmentStatus({
        authAxios,
        appointmentId,
        orderStatusDto: {status: status}
      });
      setSelected(status);
    } catch (error) {
      setError(true);
    }
  }

  return (
    <Dropdown bsPrefix="custom-dropdown">
      {error && (
        <div class="invalid-feedback d-block text-center position-absolute" style={{top: -25}}>
          <i class="fas fa-exclamation-triangle"></i> Δεν ενημερώθηκε
        </div>
      )}
      <Dropdown.Toggle
        variant="none"
        className="p-2 p-md-3 d-flex align-items-center justify-content-between"
      >
        {statusSelected && (
          <span>
            <i className="fa fa-circle mr-3" style={{color: statusSelected.color}} />
            <span className="fs-16">{statusSelected.label}</span>
          </span>
        )}

        {status !== 'DELIVERED' && <i className="fs-18 fa fa-angle-down ml-4 text-primary-main" />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {status !== 'DELIVERED' &&
          statuses.map((item) => (
            <Dropdown.Item key={item.status} onClick={() => onSetStatus(item.status)}>
              <i className="fa fa-circle fs-10 mr-3" style={{color: item.color}} />
              {item.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownActions.defaultProps = {
  toggleClass: ''
};

export default DropdownActions;
