import React, {Fragment, useMemo} from 'react';
import {Col, Form} from 'react-bootstrap';
import useForm from 'hooks/useForm';
import {useAuthFetch} from 'context/agent/fetch-context';
import useAgentNewAppointment from 'hooks/useAgentNewAppointment';
import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import Button from 'components/common/Button';
import DatesOpen from './DatesOpen';
import TimeSlots from './TimeSlots';
import CustomerDetails from './CustomerDetails';
import customerInfoSchema from 'validations/customerInfoSchema';
import {createNewAppointment} from 'api/agent/appointment';
import {parseAppointmentRequestBody} from 'utils/pojos';
import '@reach/dialog/styles.css';

const isInvalidForm = (validFields, selectedDate, selectedSlot) => {
  return [validFields, Boolean(selectedDate), Boolean(selectedSlot)].includes(false);
};

const customerFields = (values, errors) => [
  {value: values.forename, error: errors.forename, placeholder: 'Όνομα', controlId: 'forename'},
  {value: values.surname, error: errors.surname, placeholder: 'Επίθετο', controlId: 'surname'},
  {value: values.tel, error: errors.tel, placeholder: 'Τηλέφωνο', controlId: 'tel'},
  {value: values.email, error: errors.email, placeholder: 'Email', controlId: 'email'}
];

const Columns = ({close}) => {
  const {authAxios} = useAuthFetch();
  const [
    brand,
    storeId,
    loadingSubmit,
    errorSubmit,
    loadingTime,
    errorTime,
    datesOpen,
    selectedDate,
    availableSlots,
    selectedSlot,
    onSelectDate,
    onSelectSlot,
    setLoadingSubmit,
    setErrorSubmit
  ] = useAgentNewAppointment();
  const {values, handleChange, handleSubmit, handleBlur, valid, touched, errors} = useForm({
    initialValues: {
      forename: '',
      surname: '',
      tel: '',
      email: ''
    },
    onSubmit: onSubmitForm,
    validationSchema: customerInfoSchema
  });

  async function onSubmitForm() {
    setLoadingSubmit(true);
    setErrorSubmit(false);
    try {
      const postData = parseAppointmentRequestBody({
        appointment: {
          requestedTime: selectedSlot.time,
          surname: values.surname,
          forename: values.forename,
          email: values.email,
          tel: values.tel
        },
        slot: {...selectedSlot}
      });
      await createNewAppointment(authAxios, storeId, selectedDate, brand, postData);
      close();
    } catch (error) {
      setErrorSubmit(error?.code ?? true);
      setLoadingSubmit(false);
    }
  }

  const selectedPeriod = selectedSlot?.period;
  const customerInfo = useMemo(() => customerFields(values, errors), [values, errors]);
  const isDisabled = useMemo(() => isInvalidForm(valid, selectedDate, selectedPeriod), [
    valid,
    selectedDate,
    selectedPeriod
  ]);

  if (errorSubmit || loadingSubmit) {
    return (
      <Col xs={12}>
        <div className="pt-md-3 d-flex justify-content-between align-items-center">
          <div>Προσθήκη ραντεβού</div>
          <button className="icon button--nostyle" onClick={close}>
            <i className="fa fa-times-circle" aria-hidden="true" />
          </button>
        </div>
        {errorSubmit && <ErrorInfo text={errorSubmit} hideHr />}
        {loadingSubmit && <Loader wrapperClasses="m-4" />}
      </Col>
    );
  }

  return (
    <Fragment>
      <Col xs={12} md={8} className="p-4 border-right">
        <div className="pt-md-3 d-flex justify-content-between align-items-center">
          <div>Προσθήκη ραντεβού</div>
          <button className="icon button--nostyle d-md-none" onClick={close}>
            <i className="fa fa-times-circle" aria-hidden="true" />
          </button>
        </div>
        <div className="pt-4 pb-2">
          {datesOpen.length > 0 && <DatesOpen datesOpen={datesOpen} onSelectDate={onSelectDate} />}
          {availableSlots.length > 0 && (
            <TimeSlots
              availableSlots={availableSlots}
              selectedPeriod={selectedPeriod}
              onSelectSlot={onSelectSlot}
            />
          )}
          {errorTime && <ErrorInfo text={errorTime} hideHr />}
          {loadingTime && <Loader wrapperClasses="m-4" />}
        </div>
      </Col>
      <Col xs={12} md={4} className="p-4">
        <div className="position-relative h-100">
          <div className="d-flex justify-content-between align-items-center">
            <span>Πελάτης</span>
            <button className="icon button--nostyle d-none d-md-block" onClick={close}>
              <i className="fa fa-times-circle" aria-hidden="true" />
            </button>
          </div>
          <Form onSubmit={handleSubmit}>
            <CustomerDetails
              customerInfo={customerInfo}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
            />
            <div className="py-5" />
            <Button
              variant="primary"
              wrapperClass="position-absolute mt-0 agent-appointment-form-btn"
              label="Προσθήκη"
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          </Form>
        </div>
      </Col>
    </Fragment>
  );
};

export default Columns;
