import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {SET_APPOINTMENT_STATUSES} from 'context/agent/constants/ActionTypes';

import AppointmentsList from 'components/Agent/Appointments/List';
import AppointmentsBar from 'components/Agent/Appointments/Bar';
import {AuthenticatedRoute} from 'components/Agent';
import Comment from 'components/Agent/Features/Comment';
import Reschedule from 'components/Agent/Features/Reschedule';
import NewAppointment from 'components/Agent/Features/NewAppointment';
import {CustomerProvider} from 'context/customer/customer-context';
import {getAppointmentStatuses} from 'api/agent/appointment';
import {useAppointmentDispatch} from 'context/agent/appointment-context';
import {useAuthFetch} from 'context/agent/fetch-context';
import {reducer, initialState} from 'context/customer/customerReducer';

const Appointments = () => {
  const match = useRouteMatch();
  const dispatch = useAppointmentDispatch();
  const {authAxios} = useAuthFetch();

  useEffect(() => {
    const statuses = getAppointmentStatuses({authAxios});
    dispatch({type: SET_APPOINTMENT_STATUSES, payload: statuses});
  }, [authAxios, dispatch]);

  return (
    <>
      <AppointmentsBar />
      <AppointmentsList />
      <AuthenticatedRoute path={`${match.path}/new-appointment`} component={NewAppointment} />
      <AuthenticatedRoute path={`${match.path}/:appointmentId/comment`} component={Comment} />
      <CustomerProvider reducer={reducer} initialState={initialState}>
        <AuthenticatedRoute
          path={`${match.path}/:appointmentId/reschedule`}
          component={Reschedule}
        />
      </CustomerProvider>
    </>
  );
};

export default Appointments;
