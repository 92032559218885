import {useState} from 'react';

const useDialog = ({isOpen, onClose}) => {
  const [showDialog, setShowDialog] = useState(Boolean(isOpen));

  const open = () => setShowDialog(true);
  const close = () => {
    onClose ? onClose() : setShowDialog(false);
  };

  return {open, close, showDialog};
};

export default useDialog;
