import {useEffect, useState} from 'react';
import moment from 'moment';

import {useAuthFetch} from 'context/agent/fetch-context';
import {useAppointmentState} from 'context/agent/appointment-context';
import {getAppointmentCount} from 'api/agent/appointment';
import {useAuth} from 'context/agent/auth-context';

const createCalendarEvents = (data) => {
  return data.map(({day, count, hasPending}) => {
    const date = moment(day).toDate();
    return {
      start: date,
      end: date,
      count,
      allDay: true,
      title: `${count} ραντεβού`,
      color: hasPending ? 'default' : 'success',
      text: hasPending ? 'gray-disabled' : 'white'
    };
  });
};

const useCalendar = () => {
  const {authAxios} = useAuthFetch();
  const {inStore} = useAppointmentState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [appointmentsCount, setAppointmentsCount] = useState([]);
  const [date, setDate] = useState(moment(new Date()));
  const {authState, getStoreId} = useAuth();
  const storeId = getStoreId();
  useEffect(() => {
    async function onGetAppointmentCount() {
      setLoading(true);
      setError(false);

      try {
        const response = await getAppointmentCount(authAxios, {
          month: date.month() + 1,
          year: date.year(),
          storeId: storeId,
          brand: authState?.brand,
          bookingType: inStore ? 'inShop' : 'away'
        });
        setAppointmentsCount(createCalendarEvents(response));
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    onGetAppointmentCount();
  }, [authAxios, authState, authState.brand, date, storeId, inStore]);

  return [date, setDate, loading, error, appointmentsCount];
};

export default useCalendar;
