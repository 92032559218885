import React, {Fragment, useState, useEffect} from 'react';

import SectionBrand from 'components/Agent/SectionBrand';
import DateSelector from 'components/Agent/Appointments/Bar/DateSelector';
import Search from 'components/Agent/Appointments/Bar/Search';
import Filter from 'components/Agent/Appointments/Bar/Filter';
import Export from 'components/Agent/Appointments/Bar/Export';
import CreateAppointment from 'components/Agent/Appointments/Bar/CreateAppointment';
import ExportReportsCSV from 'components/Agent/Appointments/Bar/ExportReportsCSV';
import InStoreSwitch from 'components/Agent/Appointments/Bar/InStoreSwitch';
import {useAuth} from 'context/agent/auth-context';
import {useAppointmentDispatch, useAppointmentState} from 'context/agent/appointment-context';
import {toggleQueried} from 'context/agent/actions';

const AppointmentsBar = () => {
  const {isCallCenter, isUser} = useAuth();
  const callCenterAgent = isCallCenter();
  const callUser = isUser();
  const dispatch = useAppointmentDispatch();
  const {inStore} = useAppointmentState();
  const [expand, setExpand] = useState(() => !!callCenterAgent);

  useEffect(() => {
    if (!callCenterAgent) {
      toggleQueried({dispatch, queried: expand});
    }
  }, [callCenterAgent, dispatch, expand]);

  const toggleSearch = () => {
    if (!callCenterAgent) {
      setExpand((expand) => !expand);
    }
  };

  const onSelectFilter = (filter) => {
    dispatch({type: 'SET_FILTER', payload: filter});
  };

  return (
    <SectionBrand sectionClass="bar fixed-top row">
      {callCenterAgent && <ExportReportsCSV />}
      {callUser && inStore && <CreateAppointment />}
      {!expand && callUser && <Export />}
      <Search onToggle={toggleSearch} expand={expand} isClickSubmitEnabled={callCenterAgent} />
      {!expand && (
        <Fragment>
          <DateSelector />
          {!inStore && <Filter onSelectFilter={onSelectFilter} />}
        </Fragment>
      )}
      <InStoreSwitch expand={expand} />
    </SectionBrand>
  );
};

export default AppointmentsBar;
