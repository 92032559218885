import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {Col} from 'react-bootstrap';

const DatesOpen = ({datesOpen = [], date = '', onClickDate = () => null, appointment}) => {
  const getSelectedDate = React.useCallback(() => {
    return datesOpen.find((d) => d === appointment.date);
  }, [appointment.date, datesOpen]);

  React.useEffect(() => {
    const selected = getSelectedDate();
    selected && onClickDate(selected.date);
  }, [getSelectedDate, onClickDate]);

  return (
    <section className="fieldset mt-4">
      <div className="dates-open slot-dates d-flex justify-content-between pb-3">
        {datesOpen.map(({date: dateOpen, available}) => {
          const selectedDate = date === dateOpen;
          const classes = classnames('slot date', {
            disabled: !available,
            selected: selectedDate
          });

          return (
            <Col
              key={dateOpen}
              className="d-flex flex-column align-items-center mr-3 mr-sm-0 slot-col"
              onClick={() => (available || !selectedDate ? onClickDate(dateOpen) : undefined)}
            >
              {moment(dateOpen).format('dd')}
              <span className={classes}>{moment(dateOpen).format('D')}</span>
            </Col>
          );
        })}
      </div>
    </section>
  );
};

export default DatesOpen;
