import React, {useState, useCallback, useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';

import {dateFormatted} from 'utils/time';
import {appointmentsRoute} from 'utils/routes';
import {useAuthFetch} from 'context/agent/fetch-context';
import {useAppointmentState} from 'context/agent/appointment-context';
import {getAppointmentComment, getAppointment, commentAppointment} from 'api/agent/appointment';
import Button from 'components/common/Button';
import OrderDialog from 'components/Agent/Features/OrderDialog';
import useDialog from 'hooks/useDialog';
import Loader from 'components/common/Loader';
import ErrorInfor from 'components/Customer/Actions/ErrorInfo';

const Comment = () => {
  const history = useHistory();
  const {date: dateParam, ...params} = useParams();
  const [appointment, setAppointmentDetails] = useState(() => ({
    forename: '',
    surname: '',
    tel: '',
    email: '',
    orderId: '',
    date: ''
  }));

  const {authAxios} = useAuthFetch();
  const {lang} = useAppointmentState();
  const [comment, setComment] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {showDialog, close} = useDialog({
    isOpen: true,
    onClose: () => history.push(appointmentsRoute(dateParam))
  });

  const handleCommentChange = (e) => setComment(e.target.value);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await commentAppointment({authAxios, appointmentId: params.appointmentId, comment});
      setLoading(false);
      history.push(appointmentsRoute(dateParam));
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const parse = useCallback((comments) => {
    try {
      return {
        text: comments[comments.length - 1].comment,
        created: dateFormatted(comments[comments.length - 1].created)
      };
    } catch (error) {
      return {
        text: '',
        created: ''
      };
    }
  }, []);

  const onGetComments = useCallback(async () => {
    try {
      const comments = await getAppointmentComment({
        authAxios,
        appointmentId: params.appointmentId
      });
      return comments;
    } catch (error) {
      throw error;
    }
  }, [authAxios, params.appointmentId]);

  useEffect(() => {
    async function onGetAppointment() {
      try {
        setLoading(true);
        const {forename, surname, tel, email, orderId, date, time} = await getAppointment({
          authAxios,
          appointmentId: params.appointmentId,
          lang
        });
        const comments = await onGetComments();
        setAppointmentDetails({forename, surname, tel, email, orderId, date, time});
        setComment(parse(comments));
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }

    params.appointmentId && onGetAppointment();
  }, [authAxios, dateParam, lang, onGetComments, params.appointmentId, parse]);

  return (
    <OrderDialog showDialog={showDialog} appointment={appointment} close={close}>
      <Form onSubmit={onSubmit}>
        {error && <ErrorInfor text={error.code} hideHr />}
        {loading && !error && <Loader />}
        {!error && !loading && (
          <section className="fieldset">
            <label>Σχόλιο</label>
            <Form.Control value={comment.text} as="textarea" onChange={handleCommentChange} />
          </section>
        )}
        <Button
          type="submit"
          label="Αποθήκευση"
          wrapperClass="mt-5 text-right"
          disabled={comment.length === 0 || loading}
        />
      </Form>
    </OrderDialog>
  );
};

export default Comment;
