import React, {Fragment, useState} from 'react';
import Basket from 'components/Agent/Features/Basket';

const paymentIcon = {
  'Πιστωτική κάρτα': 'fa fa-credit-card',
  creditCard: 'fa fa-credit-card',
  'Pay In Store': 'fa fa-store',
  PayInStore: 'fa fa-store',
  paypal: 'fab fa-paypal',
  Paypal: 'fab fa-paypal',
  'Bank Deposit': 'fa fa-university'
};

const OrderDetails = ({wrapperClass, appointment}) => {
  const [dialog, setDialog] = useState(false);

  const {paymentMethod = ''} = appointment.order?.orderBillingInfo;
  const cartItemExists = (appointment.order?.cartItems || []).length > 0;

  const toggleDialog = () => setDialog((dialog) => !dialog);

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="mb-1 d-flex align-items-center">
          <i className={`${paymentIcon[paymentMethod] || 'fa fa-coins'} mr-2`} />
          {paymentMethod}
        </div>
        {cartItemExists && (
          <div className="mb-1 d-flex align-items-center cursor-pointer" onClick={toggleDialog}>
            <i className="fa fa-shopping-cart  mr-2" />
            Δες το καλάθι
          </div>
        )}
      </div>
      {cartItemExists && (
        <Basket appointment={appointment} dialog={dialog} toggleDialog={toggleDialog} />
      )}
    </Fragment>
  );
};

OrderDetails.defaultProps = {
  wrapperClass: ''
};

export default OrderDetails;
