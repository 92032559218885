import React from 'react';
import {Col, Row} from 'react-bootstrap';
import classnames from 'classnames';

import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';

const TimeSlots = ({
  appointment,
  loadingSlot,
  errorSlot,
  errorDate,
  slot,
  setSlot,
  availableSlots
}) => {
  const getSelectedSlot = React.useCallback(() => {
    return availableSlots.find((s) => s.time === appointment.time);
  }, [appointment.time, availableSlots]);

  React.useEffect(() => {
    const selected = getSelectedSlot();
    selected && setSlot({time: selected.time, endTime: selected.endTime});
  }, [getSelectedSlot, setSlot]);

  return (
    <>
      {loadingSlot && <Loader wrapperClasses="m-4" />}
      {errorSlot.show && <ErrorInfo text={errorDate.code} />}
      {!(loadingSlot || errorSlot.show) && (
        <>
          {availableSlots.length > 0 && (
            <div className="mt-5 mb-2 font-weight-semibold text-primary-main">Διάλεξε ώρα</div>
          )}
          <Row className="m-0">
            {availableSlots.map(({time, endTime, available}) => {
              const period = `${time.slice(0, -3)} - ${endTime.slice(0, -3)}`;
              const classes = classnames('slot time', {
                disabled: !available,
                selected: time === slot?.time && endTime === slot?.endTime
              });

              return (
                <Col
                  key={period}
                  xs={6}
                  sm={3}
                  className="px-1"
                  onClick={() => (available ? setSlot({time, endTime}) : undefined)}
                >
                  <div className={classes}>{period}</div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

export default TimeSlots;
