import React from 'react';
import {useHistory, useParams, Link} from 'react-router-dom';
import moment from 'moment';
import {Col} from 'react-bootstrap';
import {getDayFormat} from 'utils/time';

import {useAppointmentState} from 'context/agent/appointment-context';

const DateSelector = () => {
  const history = useHistory();
  const {date: dateParam} = useParams();
  const date = moment(dateParam);
  const {appointments} = useAppointmentState();

  const FORMAT_DATE = 'YYYY-MM-DD';

  const prevDay = moment(date).subtract(1, 'day').format(FORMAT_DATE);
  const nextDay = moment(date).add(1, 'day').format(FORMAT_DATE);
  const dayFormat = getDayFormat(date);

  const delivered = Array.isArray(appointments)
    ? appointments.filter((item) => item.orderStatus?.status === 'DELIVERED')
    : [];

  return (
    <Col className="d-flex justify-content-around align-items-center m-auto flex-shrink-0">
      <Link
        to={`/agent/appointments/${prevDay}`}
        className="cursor-pointer mr-5 p-2 text-white text-decoration-none"
      >
        <i className="icon fa fa-chevron-left text-white" />
      </Link>
      <div
        className="d-flex align-items-center justify-content-center cursor-pointer text-left p-2 flex-grow-1 flex-shrink-0"
        onClick={() => history.push('/agent/calendar')}
      >
        <i className="fa fa-calendar-alt mr-2" />
        <div className="nof-aps">
          {dayFormat}
          <br />
          <span className="fs-12 mt-1 d-inline-block">
            {appointments.length} ραντεβού
            {appointments.length > 0 ? ` - ${delivered.length} παραδόθηκαν` : ''}
          </span>
        </div>
      </div>
      <Link
        to={`/agent/appointments/${nextDay}`}
        className="cursor-pointer ml-5 p-2 text-white text-decoration-none"
      >
        <i className="icon fa fa-chevron-right text-white" />
      </Link>
    </Col>
  );
};

export default DateSelector;
