import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'react-bootstrap';

import Group from 'components/Agent/Appointments/Group';
import AppointmentItem from 'components/Agent/Appointments/Item';
import {useAuth} from 'context/agent/auth-context';
import {useAppointmentState} from 'context/agent/appointment-context';

const ListTransformer = ({data, queried, dayFormat}) => {
  const {isCallCenter} = useAuth();
  const {inStore} = useAppointmentState();
  const shouldRenderQueried = isCallCenter() || (queried && Array.isArray(data));
  const shouldRenderGrouped = data.constructor === Object && !isCallCenter();

  if (
    (Array.isArray(data) && data.length === 0) ||
    (Object.keys(data).length === 0 && data.constructor === Object)
  ) {
    return (
      <div
        className="d-flex w-100 justify-content-center align-items-center"
        style={{position: 'absolute', top: -80, bottom: 0}}
      >
        <h4 className="text-center text-muted position-relative">
          Δεν βρέθηκαν ραντεβού {!shouldRenderQueried ? `για ${dayFormat}` : ''}
        </h4>
      </div>
    );
  }

  if (shouldRenderQueried) {
    return data.map((appointment) => (
      <Container key={appointment.appointmentId} className="px-55">
        <AppointmentItem
          {...appointment}
          isCallCenter={isCallCenter()}
          inStore={inStore}
          type="search"
        />
      </Container>
    ));
  }

  if (shouldRenderGrouped) {
    return Object.keys(data).map((key) => (
      <Group key={key} group={key}>
        {data[key].map((appointment) => (
          <AppointmentItem
            key={appointment.appointmentId}
            {...appointment}
            isCallCenter={isCallCenter()}
            inStore={inStore}
          />
        ))}
      </Group>
    ));
  }

  return null;
};

ListTransformer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  queried: PropTypes.bool.isRequired,
  dayFormat: PropTypes.string.isRequired
};

export default ListTransformer;
