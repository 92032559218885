import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import {Spring, animated} from 'react-spring/renderprops';
import {Col} from 'react-bootstrap';

import {searchAppointment} from 'api/agent/appointment';
import {useAuthFetch} from 'context/agent/fetch-context';
import {useAppointmentState, useAppointmentDispatch} from 'context/agent/appointment-context';
import {useAuth} from 'context/agent/auth-context';
import {searchAppointmentSuccess, searchAppointmentFail} from 'context/agent/actions';

const SearchAppointments = ({expand, onToggle, isClickSubmitEnabled}) => {
  const [query, setQuery] = useState('');
  const {authAxios} = useAuthFetch();
  const {authState, getStoreId} = useAuth();
  const dispatch = useAppointmentDispatch();
  const {lang, inStore} = useAppointmentState();
  const storeId = getStoreId();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const searchResults = await searchAppointment({
          authAxios,
          brand: authState?.brand,
          value: query,
          lang,
          storeId,
          bookingType: inStore ? 'inShop' : 'away'
        });
        searchAppointmentSuccess({dispatch, searchResults});
      } catch (error) {
        searchAppointmentFail({dispatch});
      }
    },
    [authAxios, authState.brand, dispatch, lang, query, storeId, inStore]
  );

  const onKeyup = useCallback(
    (event) => {
      if (event.keyCode === 27 && expand) return onToggle();
      !expand && setQuery('');
    },
    [expand, onToggle]
  );

  const onHandleClick = useCallback(
    (e) => {
      if (isClickSubmitEnabled) {
        handleSubmit(e);
      } else {
        onToggle();
      }
    },
    [handleSubmit, isClickSubmitEnabled, onToggle]
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyup);
    return () => {
      window.removeEventListener('keyup', onKeyup);
    };
  }, [onKeyup]);

  const handleSearch = (event) => setQuery(event.target.value);

  const buttonClasses = classnames('icon button--nostyle text-white', {
    expand
  });

  const inputClasses = classnames('', {
    visible: expand,
    invisible: !expand,
    'opacity-10': expand,
    'opacity-0': !expand
  });

  return (
    <Col id="search-component" className="d-flex flex-shrink-1">
      <Spring
        native
        from={{transform: !expand ? 'translateX(0%)' : 'trandlateX(-100%)'}}
        to={{
          width: expand ? '100%' : '2%',
          opacity: 1,
          transform: expand ? 'translateX(-100%)' : 'trandlateX(0)'
        }}
      >
        {(props) => (
          <animated.div style={{width: props.width}} className="d-flex">
            <Form
              id="search-form"
              onSubmit={handleSubmit}
              className="mr-auto d-flex align-items-center w-100"
            >
              <Form.Control
                name="search"
                type="text"
                onChange={handleSearch}
                value={query || ''}
                className={inputClasses}
                placeholder="Πληκτρολόγηστε αριθμό παραγγελίας, τηλέφωνο ή email και πατήστε Enter"
              />
            </Form>
            <animated.button
              className={buttonClasses}
              onClick={onHandleClick}
              style={{left: props.width, transform: props.transform}}
            >
              <i className="fa fa-search" />
            </animated.button>
          </animated.div>
        )}
      </Spring>
    </Col>
  );
};

SearchAppointments.proptTypes = {
  expand: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default SearchAppointments;
