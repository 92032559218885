import React, {useState, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Dialog} from '@reach/dialog';
import {useHistory, useParams} from 'react-router-dom';

import '@reach/dialog/styles.css';

import Button from 'components/common/Button';
import DatesOpen from './DatesOpen';
import TimeSlots from './TimeSlots';
import {appointmentsRoute} from 'utils/routes';
import {getAppointment} from 'api/agent/appointment';
import {useAuthFetch} from 'context/agent/fetch-context';
import {useAppointmentState, useAppointmentDispatch} from 'context/agent/appointment-context';
import {useAuth} from 'context/agent/auth-context';
import {useCustomerState, useCustomerDispatch} from 'context/customer/customer-context';
import CustomerDetails from 'components/Agent/CustomerDetails';
import useDialog from 'hooks/useDialog';
import useCustomerDatetime from 'hooks/useCustomerDatetime';

import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import {rescheduleAppointment, searchAppointment} from 'api/agent/appointment';
import {parseRequestBody} from 'utils/appointment';
import {errors} from 'utils/error';
import {searchAppointmentSuccess} from 'context/agent/actions';

const Reschedule = () => {
  const history = useHistory();
  const {date: dateParam, ...params} = useParams();
  const [appointment, setAppointmentDetails] = useState(() => ({
    forename: '',
    surname: '',
    tel: '',
    email: '',
    orderId: '',
    date: ''
  }));
  const {authState} = useAuth();

  const {authAxios} = useAuthFetch();
  const {inStore, lang} = useAppointmentState();
  const [error, setError] = useState(false);
  const dispatch = useAppointmentDispatch();
  const {showDialog, close} = useDialog({
    isOpen: true,
    onClose: () => history.push(appointmentsRoute(dateParam))
  });

  const dateTimeProps = useCustomerDatetime({
    bookingType: inStore ? 'inShop' : 'away',
    useDispatchHook: useCustomerDispatch,
    useStateHook: useCustomerState,
    storeId: appointment.storeId,
    onSubmitPromiseCallback: async (data) => await Promise.resolve(data)
  });

  const onReschedule = async () => {
    const {
      dateTimeState: {date, slot}
    } = await dateTimeProps.onSubmit();

    try {
      setError(false);
      await rescheduleAppointment({
        typeFetch: authAxios,
        brand: authState?.brand,
        storeId: appointment.storeId,
        date,
        payload: parseRequestBody({
          appointmentId: params.appointmentId,
          appointment,
          slot
        })
      });
      const searchResults = await searchAppointment({
        authAxios,
        brand: authState?.brand,
        value: appointment.email,
        lang: 'el',
        storeId: appointment.storeId
      });
      close();
      searchAppointmentSuccess({dispatch, searchResults});
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    async function onGetAppointment() {
      try {
        setError(false);
        const appointment = await getAppointment({
          authAxios,
          appointmentId: params.appointmentId,
          lang
        });
        setAppointmentDetails(appointment);
      } catch (error) {
        setError(error);
      }
    }

    params.appointmentId && onGetAppointment();
  }, [authAxios, lang, params.appointmentId]);

  return (
    <Dialog
      isOpen={showDialog}
      onDismiss={close}
      className="main-card position-relative"
      aria-label="Comment"
    >
      <Row>
        <Col style={{flexGrow: 30, flexShrink: 0}}>
          <CustomerDetails {...appointment} />
        </Col>
        <Col style={{flexGrow: 70, maxWidth: '100%', overflow: 'hidden'}}>
          {error && <ErrorInfo text={errors[error?.code]} />}
          {!error && dateTimeProps.loadingDate && <Loader wrapperClasses="m-4" />}
          {!error && dateTimeProps.errorDate.show && (
            <ErrorInfo text={dateTimeProps.errorDate.code} hideHr />
          )}
          {!error && !(dateTimeProps.loadingDate || dateTimeProps.errorDate.show) && (
            <>
              <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
                Διάλεξε ημερομηνία
              </div>
              <>
                <DatesOpen {...dateTimeProps} appointment={appointment} />
                <TimeSlots {...dateTimeProps} appointment={appointment} />
                {dateTimeProps.date && dateTimeProps.slot && (
                  <Button
                    variant="secondary"
                    wrapperClass="text-right mt-4"
                    label="Αποθήκευση"
                    onClick={onReschedule}
                  />
                )}
              </>
            </>
          )}
        </Col>
      </Row>
      <button className="icon button--noStyle dialog-close" onClick={close}>
        <i className="fa fa-times-circle" aria-hidden="true"></i>
      </button>
    </Dialog>
  );
};

export default Reschedule;
