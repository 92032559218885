import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import Banner from 'components/common/Banner';

export default ({children}) => {
  return (
    <Container fluid>
      <Row className="l-customer-header">
        <Banner />
      </Row>
      <Row className="l-customer-content">
        <Container fluid className="l-customer-content-container">
          <Row>
            <Col xs={12} lg={6} />
            <Col xs={12} lg={6}>
              {children}
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
