import React from 'react';
import {Col, Form} from 'react-bootstrap';
import FieldError from 'components/common/FieldError';

const CustomerDetails = ({customerInfo, touched, onChange, onBlur}) => (
  <Form.Row className="py-3">
    {customerInfo.map(({controlId, value, placeholder, error}) => (
      <Col key={controlId} xs={12} className="mt-3">
        <Form.Group controlId={controlId}>
          <Form.Control
            name={controlId}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ''}
            type="text"
            placeholder={placeholder}
            className="rbt-input-main rbt-input"
            isInvalid={!!error && touched}
          />
          <Form.Label>
            <span>{placeholder}</span>
          </Form.Label>
          <FieldError error={error} touched={touched} />
        </Form.Group>
      </Col>
    ))}
  </Form.Row>
);

export default CustomerDetails;
