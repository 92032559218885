import moment from 'moment';

const dateFormatted = (date) => moment(date).format('dddd D MMMM YYYY');
const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow;
};
const getISODate = (date) => moment(date).format('YYYY-MM-DD');
const dateIsAfter = (a, b) => moment(a).isSameOrAfter(b, 'day');
const dateIsSame = (a, b) => moment(a).isSame(b, 'day');
const dateIsBefore = (a, b) => moment(a).isBefore(b, 'day');

const getDayFormat = (date) => {
  const momentDate = moment(date);
  const currentDate = moment();
  currentDate.startOf('day');

  return momentDate.isSame(currentDate)
    ? `Σήμερα, ${momentDate.format('D MMM')}`
    : momentDate.format('dddd, D MMM');
};

/**
 *
 * @param {Date} date
 * @param {String} time, a string like 17:00:00
 */
const getSypnosisDate = (date, time) => {
  try {
    return moment(date)
      .format('dd,ll')
      .split(',')
      .map((seg, idx) => {
        if (idx === 0) {
          seg = seg + ',';
        }

        return seg;
      })
      .slice(0, 2)
      .concat(time.slice(0, 5))
      .join(' ');
  } catch (error) {
    return '';
  }
};

export {
  dateFormatted,
  getTomorrow,
  getISODate,
  dateIsAfter,
  dateIsBefore,
  getDayFormat,
  dateIsSame,
  getSypnosisDate
};
