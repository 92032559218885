import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
// import  from 'react-bootstrap/AccordionContext';
import classnames from 'classnames';
import {Col, AccordionContext, Container} from 'react-bootstrap';

const Toggle = ({group}) => {
  const currentEventKey = useContext(AccordionContext);
  const chevronClasses = classnames('fa fs-24 icon mr-4', {
    'fa-plus': currentEventKey !== group,
    'fa-minus': currentEventKey === group
  });

  return (
    <div className="accordion-toggle d-flex flex-grow-1 align-items-center container">
      <i className={chevronClasses} />
      <span className="fs-16">{group}</span>
    </div>
  );
};

Toggle.propTypes = {
  group: PropTypes.string
};

const Group = ({group, children}) => {
  return (
    <Col className="px-0 flex-grow-0">
      <Accordion key={group}>
        <Accordion.Toggle variant="link" eventKey={group}>
          <Toggle group={group} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={group} className="accordion-collapse">
          <Container className="px-55">{children}</Container>
        </Accordion.Collapse>
      </Accordion>
    </Col>
  );
};

Group.propTypes = {
  group: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultActiveKey: PropTypes.string
};

export default Group;
