import React, {Fragment} from 'react';
import {Col, Row, Dropdown} from 'react-bootstrap';
import classnames from 'classnames';

const TimeSlots = ({availableSlots, selectedPeriod, onSelectSlot}) => (
  <Fragment>
    <Dropdown bsPrefix="custom-dropdown mt-4 d-sm-none">
      <Dropdown.Toggle
        variant="none"
        className="p-2 p-md-3 d-flex align-items-center justify-content-between w-100"
      >
        <span className="fs-16">{selectedPeriod ?? 'Ώρα'}</span>
        <i className="fs-18 fa fa-angle-down ml-4 text-primary-main" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {availableSlots.map(({time, endTime, period}) => (
          <Dropdown.Item
            key={period}
            className="text-center"
            onClick={() => onSelectSlot({time, endTime})}
          >
            {period}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    <div className="d-none d-sm-block">
      <Row className="m-0">
        {availableSlots.map(({time, endTime, period, available, selected}) => {
          const classes = classnames('slot time', {
            disabled: !available,
            selected
          });

          return (
            <Col
              key={period}
              xs={6}
              sm={3}
              className="px-1"
              onClick={() => (!available || selected ? undefined : onSelectSlot({time, endTime}))}
            >
              <div className={classes}>{period}</div>
            </Col>
          );
        })}
      </Row>
    </div>
  </Fragment>
);

export default TimeSlots;
