import React from 'react';
import Switch from 'react-switch';
import classnames from 'classnames';

import {useAppointmentState, useAppointmentDispatch} from 'context/agent/appointment-context';
import {toggleInStore} from 'context/agent/actions';

export default function InStoreSwitch({expand}) {
  const {inStore} = useAppointmentState();
  const dispatch = useAppointmentDispatch();
  const classes = classnames('d-flex justify-content-end align-items-center', {
    col: !expand && inStore,
    'flex-shrink-1': !expand
  });

  return (
    <div className={classes}>
      <Switch
        onChange={() => toggleInStore({dispatch, payload: !inStore})}
        checked={inStore}
        uncheckedIcon={
          <div className="switch">
            <span role="button" className="icon-header fa fa-shopping-bag" />
          </div>
        }
        checkedIcon={
          <div className="switch">
            <span role="button" className="icon-header fa fa-walking" />
          </div>
        }
      />
    </div>
  );
}
