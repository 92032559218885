import {useState} from 'react';

const useStatus = (status, statuses = []) => {
  const [selected, setSelected] = useState(() => status || statuses[0]?.status);
  const statusSelected = statuses.find((item) => item.status === selected);
  const [error, setError] = useState(false);

  return {statusSelected, error, selected, setSelected, statuses, setError};
};

export default useStatus;
