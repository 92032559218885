import React, {Fragment, useState} from 'react';
import FileSaver from 'file-saver';
import Spinner from 'react-bootstrap/Spinner';
import {useAppointmentState} from 'context/agent/appointment-context';
import {useAuth} from 'context/agent/auth-context';
import {useAuthFetch} from 'context/agent/fetch-context';
import {getCSVReports} from 'api/agent/appointment';
import {getISODate} from 'utils/time';

const startDate = '2020-12-01';
const endDate = getISODate();

const ExportReportsCSV = () => {
  const [loading, setLoading] = useState(false);
  const {authState} = useAuth();
  const {authAxios} = useAuthFetch();
  const {inStore} = useAppointmentState();

  const onClickDownloadFile = async () => {
    setLoading(true);
    try {
      const response = await getCSVReports({
        authAxios,
        brand: authState?.brand,
        startDate,
        endDate,
        bookingType: inStore ? 'inShop' : 'away'
      });
      FileSaver.saveAs(
        new Blob([response]),
        `${inStore ? 'clickInStore' : 'pickUpCorner'}-${startDate}-to-${endDate}.csv`
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <button
        type="button"
        className="icon button--nostyle text-white"
        onClick={onClickDownloadFile}
        disabled={loading}
      >
        {loading ? (
          <Spinner animation="border" variant="light" />
        ) : (
          <i className="fa fa-file-excel" />
        )}
      </button>
    </Fragment>
  );
};

export default ExportReportsCSV;
