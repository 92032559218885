/* eslint-disable no-restricted-globals */
if (typeof window != 'object') {
  self.window = self;
}

const PUBLIC = 'public';
const MEDIAMARKT = 'mediamarkt';
const PMM = 'pmm';

const {hostname, pathname} = self.location;
const isPublic = hostname.includes('public');
const domain = isPublic ? 'https://www.public.gr/' : 'https://www.mediamarkt.gr/';

const brandParam = isPublic ? PUBLIC : MEDIAMARKT;
const isClickInShop = hostname.includes('walkin.');

const termsUrl = isPublic
  ? 'https://www.public.gr/cat/corporate/oroi/politiki-aporritoy'
  : 'https://www.mediamarkt.gr/shop/article/privacy';

const getBrandName = () => {
  const pathnameArray = pathname.split('/');

  if (pathnameArray.includes('agent')) {
    return PMM;
  }

  if (isPublic) {
    return PUBLIC;
  }

  return MEDIAMARKT;
};

const assetDirectory = getBrandName();

const displayFavicon = () => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = require(`assets/images/${assetDirectory}/favicon.ico`);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export {
  isPublic,
  assetDirectory,
  domain,
  brandParam,
  displayFavicon,
  termsUrl,
  getBrandName,
  isClickInShop
};
