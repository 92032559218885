import * as React from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = React.createContext();

/**
 *
 * @param {React.PropsWithChildren} props, React Props
 * @returns{JSX.Element}
 */
function AuthProvider(props) {
  const agent = localStorage.getItem('agent');
  const [authState, setAuthState] = React.useState(agent ? JSON.parse(agent) : {});

  const getStoreId = React.useCallback((jwt) => {
    try {
      const {storeid} = jwtDecode(jwt);
      return storeid;
    } catch (error) {
      // fail silently
    }
  }, []);

  const setAuthInfo = React.useCallback(
    (data) => {
      localStorage.setItem('agent', JSON.stringify(data));
      setAuthState({...data, storeId: getStoreId(data.jwt)});
    },
    [getStoreId]
  );

  const value = React.useMemo(
    () => ({authState, setAuthState: (authInfo) => setAuthInfo(authInfo)}),
    [authState, setAuthInfo]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

/**
 * The useAuth custom hook uses React.useContext to get the provided context value
 * from the nearest AuthProvider
 * @returns {Object} AuthState
 */
function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthState mush be used within an AuthProvider');
  }

  const {authState, setAuthState} = context;

  function isAuthenticated() {
    if (!authState.jwt) {
      return false;
    }

    const {exp} = jwtDecode(authState.jwt);

    return new Date().getTime() < new Date(exp * 1000).getTime();
  }

  function logout() {
    localStorage.removeItem('agent');
    setAuthState({});
  }

  function isAdmin() {
    return authState.role.includes('ROLE_ADMIN');
  }

  function isCallCenter() {
    return authState.role.includes('ROLE_CALL_CENTRE');
  }

  function isUser() {
    return authState.role.includes('ROLE_USER');
  }

  function getStoreId() {
    try {
      const {storeid} = jwtDecode(authState.jwt);
      return storeid;
    } catch (error) {
      // fail silently
    }
  }

  return {
    authState,
    setAuthState,
    isAuthenticated,
    logout,
    getStoreId,
    isAdmin,
    isCallCenter,
    isUser
  };
}

export {AuthProvider, useAuth};
