import React from 'react';
import {useParams} from 'react-router-dom';
import {useAppointmentState} from 'context/agent/appointment-context';
import {exportToExcel} from 'utils/file';

const getReportData = (appointments, pickUpCorner) =>
  appointments.map(
    ({forename, surname, email, tel, orderStatus, date, slotDto, status, ticketNumber, order}) => {
      const columns = {
        forename,
        surname,
        email,
        tel,
        date,
        time: slotDto.time,
        status: orderStatus?.status ?? '',
        ticketNumber
      };

      if (pickUpCorner) {
        columns.orderId = order?.orderId ?? '';
        columns.paymentMethod = order?.orderBillingInfo?.paymentMethod ?? '';
      }

      return columns;
    }
  );

const Export = () => {
  const {date} = useParams();
  const {appointments, inStore} = useAppointmentState();

  const onClickExport = () => {
    exportToExcel(
      getReportData(appointments, !inStore),
      `${inStore ? 'clickInStore' : 'pickUpCorner'}-${appointments.length}-${date}`
    );
  };

  return (
    <button className="icon button--nostyle text-white" onClick={onClickExport}>
      <i className="fa fa-file-excel" />
    </button>
  );
};

export default Export;
