import React from 'react';
import {Row} from 'react-bootstrap';
import {Dialog} from '@reach/dialog';
import {useHistory, useParams} from 'react-router-dom';
import useDialog from 'hooks/useDialog';
import {appointmentsRoute} from 'utils/routes';
import Columns from './Columns';
import '@reach/dialog/styles.css';

const NewAppointment = () => {
  const history = useHistory();
  const {date: dateParam} = useParams();
  const {showDialog, close} = useDialog({
    isOpen: true,
    onClose: () => history.push(appointmentsRoute(dateParam))
  });

  return (
    <Dialog isOpen={showDialog} onDismiss={close} aria-label="Comment" className="fs-16 main-card">
      <Row>
        <Columns close={close} />
      </Row>
    </Dialog>
  );
};

export default NewAppointment;
