import React from 'react';

const CustomerDetails = ({wrapperClass, forename, surname, tel, email}) => {
  return (
    <div className={wrapperClass}>
      <div className="mb-1 d-flex align-items-center">
        <i className="fa fa-user-alt mr-2" />
        {forename} {surname}
      </div>
      <div className="mb-1 d-flex align-items-center">
        <i className="fa fa-phone-alt mr-2" />
        {tel}
      </div>
      <div className="mb-1 d-flex align-items-center">
        <i className="fa fa-envelope mr-2" />
        {email}
      </div>
    </div>
  );
};

CustomerDetails.defaultProps = {
  wrapperClass: ''
};

export default CustomerDetails;
