import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row} from 'react-bootstrap';

const SectionBrand = ({sectionClass, children}) => {
  return (
    <section
      className={`fs-18 section-brand bg-brand text-white text-center d-flex align-items-center ${sectionClass}`}
    >
      <Container>
        <Row>{children}</Row>
      </Container>
    </section>
  );
};

SectionBrand.defaultProps = {
  sectionClass: ''
};

SectionBrand.propTypes = {
  sectionClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SectionBrand;
