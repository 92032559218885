import React from 'react';
import {Col} from 'react-bootstrap';

import Dropdown from 'react-bootstrap/Dropdown';

import useStatus from 'hooks/useStatus';

const filters = [
  {
    status: 'ALL',
    label: 'Όλα',
    color: '#fff'
  },
  {
    status: 'IN_WAREHOUSE',
    label: 'Στην Αποθήκη',
    color: '#707070'
  },
  {
    status: 'ON_PICKUP',
    label: 'Στο Pickup Corner',
    color: '#FFEB3B'
  },
  {
    status: 'DELIVERED',
    label: 'Παραδόθηκε',
    color: '#009688'
  },
  {
    status: 'NO_SHOW',
    label: 'Δεν ήρθε',
    color: '#AF4448'
  }
];

const Filter = ({onSelectFilter}) => {
  const {statusSelected, statuses, setSelected} = useStatus('ALL', filters);

  const onSelectStatus = (status) => {
    setSelected(status);
    onSelectFilter(status);
  };

  return (
    <Col className="d-flex justify-content-center align-items-center w-100 flex-shrink-1">
      <div className="ml-auto">
        <Dropdown bsPrefix="custom-dropdown">
          <Dropdown.Toggle
            variant="none"
            className="p-2 p-md-3 d-flex align-items-center justify-content-between"
          >
            {statusSelected && (
              <span>
                <i className="fa fa-circle mr-3" style={{color: statusSelected.color}} />
                <span className="fs-16">{statusSelected.label}</span>
              </span>
            )}

            <i className="fs-18 fa fa-angle-down ml-4 text-primary-main" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {statuses.map((item) => (
              <Dropdown.Item key={item.status} onClick={() => onSelectStatus(item.status)}>
                <i className="fa fa-circle fs-10 mr-3" style={{color: item.color}} />
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Col>
  );
};

export default Filter;
