import React from 'react';
import Card from 'react-bootstrap/Card';
import {useHistory, useParams} from 'react-router-dom';
import classes from 'classnames';

import {appointmentsRoute} from 'utils/routes';
import {getDayFormat} from 'utils/time';
import CustomerDetails from './CustomerDetails';
import OrderDetails from './OrderDetails';
import DropdownActions from './DropdownActions';
import DropdownInStoreActions from './DropdownInStoreActions';
import Button from 'components/common/Button';
import {useAppointmentDispatch} from 'context/agent/appointment-context';

const AppointmentRow = (appointment) => {
  const {
    forename,
    surname,
    email,
    tel,
    orderId,
    orderStatus,
    appointmentId,
    date,
    slotDto,
    storeId,
    storeName,
    type,
    isCallCenter,
    inStore,
    comments,
    status: appointmentStatus
  } = appointment;
  const {date: dateParam} = useParams();
  const history = useHistory();
  const match = appointmentsRoute(dateParam);
  const dispatch = useAppointmentDispatch();
  const openComment = () => history.push(`${match}/${appointmentId}/comment`);
  const onReschedule = () => {
    dispatch({type: 'SET_STORE_ID', payload: {orderId, storeId}});
    history.push(`${match}/${appointmentId}/reschedule`);
  };
  const isSearchMode = type === 'search';

  const commentIconClasses = classes('fa fa-comment cursor-pointer mx-5', {
    'fa-comment--enabled': comments.length > 0
  });

  const dayFormat = getDayFormat(date);

  return (
    <Card className="appointment-card fs-16 my-4">
      {isSearchMode && (
        <div className="fs-14 appointment-card-date d-flex justify-content-between align-items-center">
          <span>
            {`${dayFormat}, ${slotDto.time.slice(0, -3)} - ${slotDto.endTime.slice(0, -3)}`}
          </span>
          {isCallCenter && <span>{storeName}</span>}
        </div>
      )}
      <Card.Body>
        <div className="d-flex mb-md-0 mb-4">
          {orderId && <div className="appointment-id p-md-4 p-2 mr-4">#{orderId}</div>}

          <CustomerDetails
            forename={forename}
            surname={surname}
            email={email}
            tel={tel}
            wrapperClass="fs-14 d-none d-lg-block"
          />

          {orderId && (
            <OrderDetails
              appointment={appointment}
              wrapperClass="fs-14 ml-auto d-none d-lg-block"
            />
          )}
          <div className="side-actions">
            <i className={commentIconClasses} onClick={openComment} />
            <div className="d-none d-sm-block">
              {!isCallCenter ? (
                orderId ? (
                  <DropdownActions status={orderStatus.status} appointmentId={appointmentId} />
                ) : (
                  <DropdownInStoreActions
                    status={appointmentStatus}
                    appointmentId={appointmentId}
                  />
                )
              ) : (
                !inStore && <Button label="Αλλαγή ραντεβού" onClick={onReschedule} />
              )}
            </div>
          </div>
        </div>
        <div className="d-block d-sm-none mb-4">
          {!isCallCenter ? (
            <DropdownActions status={orderStatus.status} appointmentId={appointmentId} />
          ) : (
            !inStore && <Button label="Αλλαγή ραντεβού" onClick={onReschedule} />
          )}
        </div>
        <div className="d-flex flex-wrap">
          <CustomerDetails
            forename={forename}
            surname={surname}
            email={email}
            tel={tel}
            wrapperClass="fs-14 d-block d-lg-none customer-details"
          />
          {orderId && (
            <OrderDetails appointment={appointment} wrapperClass="fs-14 d-block d-lg-none" />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AppointmentRow;
