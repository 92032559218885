import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {Calendar as ReactCalendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';

import CalendarHeader from './CalendarHeader';
import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';

import useCalendar from 'hooks/useCalendar';

const eventColorClass = ({color, text}) => ({
  className: `bg-${color} text-${text}`
});

const Calendar = () => {
  const history = useHistory();
  const localizer = momentLocalizer(moment);

  const [date, setDate, loading, error, appointmentsCount] = useCalendar();

  const navigateToAppointment = (event) => {
    history.push(`/agent/appointments/${moment(event.start).format('YYYY-MM-DD')}`);
  };

  return (
    <Fragment>
      <CalendarHeader date={date} setDate={setDate} />
      <section className="fs-16 position-relative section-premier calendar-count">
        {loading && (
          <div className="mt-5">
            <Loader wrapperClasses="m-4" />
          </div>
        )}
        {error && <ErrorInfo />}
        {!(loading || error) && (
          <ReactCalendar
            selectable
            localizer={localizer}
            events={appointmentsCount}
            date={date.toDate()}
            eventPropGetter={eventColorClass}
            onSelectEvent={navigateToAppointment}
            tooltipAccessor={null}
            views={['month']}
            defaultDate={new Date()}
            toolbar={false}
            onNavigate={() => {}}
            startAccessor="start"
            endAccessor="end"
          />
        )}
      </section>
    </Fragment>
  );
};

export default Calendar;
