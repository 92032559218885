import React from 'react';

import {getSypnosisDate} from 'utils/time';

const CustomerDetails = (appointment) => {
  return (
    <section>
      <div className="font-weight-bold">#{appointment.orderId}</div>
      <div className="mb-5 fs-12">{getSypnosisDate(appointment.date, appointment.time)}</div>
      <div className="mb-1 d-flex fs-12 align-items-center">
        <i className="fa fa-user-alt mr-2 fs-10" />
        {appointment.forename} {appointment.surname}
      </div>
      <div className="mb-1 d-flex fs-12 align-items-center">
        <i className="fa fa-phone-alt mr-2 fs-10" />
        {appointment.tel}
      </div>
      <div className="mb-1 d-flex fs-12 align-items-center">
        <i className="fa fa-envelope mr-2 fs-10" />
        {appointment.email}
      </div>
    </section>
  );
};

export default CustomerDetails;
